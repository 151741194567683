<template>
  <ion-page>
    <Header title="Buat Testimonial" />
    <ion-content :fullscreen="true">
      <div class="container testimoni" v-if="claim">
        <!-- <alert title="Undian Harian 07 Oct 2021" desc="Periode 7 Okt 2021" link="">
          <template v-slot:start>
            <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M20.5 12V22H4.5V12" stroke="#219653" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M22.5 7H2.5V12H22.5V7Z" stroke="#219653" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M12.5 22V7" stroke="#219653" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M12.5 7H8C7.33696 7 6.70107 6.73661 6.23223 6.26777C5.76339 5.79893 5.5 5.16304 5.5 4.5C5.5 3.83696 5.76339 3.20107 6.23223 2.73223C6.70107 2.26339 7.33696 2 8 2C11.5 2 12.5 7 12.5 7Z" stroke="#219653" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M12.5 7H17C17.663 7 18.2989 6.73661 18.7678 6.26777C19.2366 5.79893 19.5 5.16304 19.5 4.5C19.5 3.83696 19.2366 3.20107 18.7678 2.73223C18.2989 2.26339 17.663 2 17 2C13.5 2 12.5 7 12.5 7Z" stroke="#219653" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </template>
        </alert> -->
        <div class="form mt-1">
          <label for="phone">Pesan</label>
          <ion-textarea v-model="content" class="input" :rows="10"></ion-textarea>

          <div class="images" v-if="images">
            <img v-for="(img, i) in images" :key="i" :src="img.url" class="thumb">
          </div>

          <ion-button class="upload" mode="ios" expand="block" shape="round" color="dark" fill="outline" @click="$refs.file.click()">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M19 3H5C3.89543 3 3 3.89543 3 5V19C3 20.1046 3.89543 21 5 21H19C20.1046 21 21 20.1046 21 19V5C21 3.89543 20.1046 3 19 3Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M8.5 10C9.32843 10 10 9.32843 10 8.5C10 7.67157 9.32843 7 8.5 7C7.67157 7 7 7.67157 7 8.5C7 9.32843 7.67157 10 8.5 10Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M21 15L16 10L5 21" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            Unggah Gambar
          </ion-button>
          <input type="file" name="file" ref="file" class="input-form-hidden" @change="upload">
          <ion-button @click="send" class="mt-1" mode="ios" expand="block" shape="round" color="dark">Kirim</ion-button>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonContent, IonPage, IonTextarea, IonButton } from '@ionic/vue'
import { defineComponent } from 'vue'
import Header from '@/components/headerPage'
// import Alert from "@/components/alert";
import axios from 'axios'

export default defineComponent({
  components: {
    IonTextarea,
    IonContent,
    IonButton,
    IonPage,
    Header,
    // Alert
  },
  data () {
    return {
      content: '',
      uploading: false,
      progress: 0,
      images: [],
      claim: null
    }
  },
  async created() {
    await this.getClaim()
  },
  watch: {
    '$route': 'getClaim'
  },
  methods: {
    async upload(){
      let formData = new FormData();
      formData.append('file', this.$refs.file.files[0]);
      this.uploading = true;
      let res = await axios.post(this.$uploadUrl,
          formData,
          {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            onUploadProgress(e) {
              let percentCompleted = Math.round((e.loaded * 100) / e.total)
              this.progress = percentCompleted
            }
          }
      );

      this.uploading = false;

      if(res.status == 200){
        let data = res.data
        this.images.push(data.data)
      }
    },
    async send () {
      let res = await this.$api.post('/api/testimonial', {
        'claim_id': this.claim.id,
        'content': this.content,
        'images': this.images
      })

      if(! res.error)
        this.$router.push('/testimoni')
    },
    async getClaim() {
      let id = this.$route.params.id
      if(id)
        this.claim = await this.$api.get(`/api/claim/${id}`).then(res => res.data)
    },
  }
})
</script>

<style lang="scss">
.upload {
  svg {
    margin-right: 5px;
  }
}
.input-form-hidden {
	display: none;
}

.images {
  img {
    object-fit: cover;
    border-radius: 5px;
    width: 50px;
    height: 50px;
    margin-right: 10px;
  }
}
</style>
